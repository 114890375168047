import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HeaderComponent } from './header/header.component';
import { FooterComponent } from './footer/footer.component';
import { LogoComponent } from './logo/logo.component';
import { InputComponent } from './input/input.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonComponent } from './button/button.component';
import { TableComponent } from './table/table.component';
import { MenuComponent } from './menu/menu.component';
import { RouterModule } from '@angular/router';
import { CheckboxComponent } from './checkbox/checkbox.component';
import { RadioGroupComponent } from './radio-group/radio-group.component';
import { DropdownComponent } from './dropdown/dropdown.component';
import { MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderUserInformationComponent } from './header-user-information/header-user-information.component';
import { PopupComponent } from './popup/popup.component';
import { TagComponent } from './tag/tag.component';
import { DateWithoutTimezone } from '../../pipes/dateWithoutTimezone.pipe';
import { ToggleComponent } from './toggle/toggle.component';
import { PromotionsTableComponent } from './promotions-table/promotions-table.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { TableColumnOptionsDropdownComponent } from './table/table-column-options-dropdown/table-column-options-dropdown.component';
import { FileUploadComponent } from './file-upload/file-upload.component';
import { TemporaryMessageComponent } from './temporary-message/temporary-message.component';
import { LoaderComponent } from './loader/loader.component';
import { DateWithCustomTimezonePipe } from '../../pipes/date-with-custom-timezone.pipe';
import { PrizesTableComponent } from '../pages/management-prizes/prizes-table/prizes-table.component';
import { ReplaceNewlineWithBrPipe } from '../../pipes/replace-new-line-with-br.pipe';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { SearchBarComponent } from './search-bar/search-bar.component';
import { BreadcrumbComponent } from './breadcrumb/breadcrumb.component';

@NgModule({
  declarations: [
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LogoComponent,
    InputComponent,
    ButtonComponent,
    TableComponent,
    CheckboxComponent,
    RadioGroupComponent,
    DropdownComponent,
    HeaderUserInformationComponent,
    PopupComponent,
    TagComponent,
    DateWithoutTimezone,
    ToggleComponent,
    PromotionsTableComponent,
    PrizesTableComponent,
    TableColumnOptionsDropdownComponent,
    FileUploadComponent,
    TemporaryMessageComponent,
    LoaderComponent,
    DateWithCustomTimezonePipe,
    ReplaceNewlineWithBrPipe,
    ConfirmationModalComponent,
    BreadcrumbComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    TranslateModule,
    MatFormFieldModule,
    MatSelectModule,
    MatCheckboxModule,
    MatIconModule,
    SearchBarComponent,
  ],
  exports: [
    TranslateModule,
    HeaderComponent,
    MenuComponent,
    FooterComponent,
    LogoComponent,
    InputComponent,
    ButtonComponent,
    TableComponent,
    CheckboxComponent,
    RadioGroupComponent,
    DropdownComponent,
    HeaderUserInformationComponent,
    PopupComponent,
    TagComponent,
    DateWithoutTimezone,
    ToggleComponent,
    PromotionsTableComponent,
    PrizesTableComponent,
    TableColumnOptionsDropdownComponent,
    FileUploadComponent,
    TemporaryMessageComponent,
    LoaderComponent,
    DateWithCustomTimezonePipe,
    ReplaceNewlineWithBrPipe,
    ConfirmationModalComponent,
    SearchBarComponent,
    BreadcrumbComponent,
  ],
  providers: [{ provide: MAT_DATE_LOCALE, useValue: 'pt-BR' }],
})
export class ComponentsModule {}
