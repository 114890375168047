<nav class="breadcrumb">
  @for (
    breadcrumb of breadcrumbService.breadcrumbs();
    track $index;
    let last = $last;
  ) {
    @if (!last) {
      <a [routerLink]="breadcrumb.url">
        <ng-content
          *ngTemplateOutlet="label; context: { $implicit: breadcrumb }"
        ></ng-content>
      </a>
      <span [ngClass]="{ last: $index + 1 === breadcrumbService.breadcrumbs().length - 1}">
        &gt;
      </span>
    } @else {
      <span class="last">
        <ng-content
          *ngTemplateOutlet="label; context: { $implicit: breadcrumb }"
        ></ng-content>
      </span>
    }
  }
</nav>

<ng-template #label let-breadcrumb>
  {{ breadcrumb.label | translate }}
  {{ breadcrumb.showCustomLabel === true ? breadcrumb.customLabel : "" }}
</ng-template>
