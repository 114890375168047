import { Injectable, signal } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs/operators';

export type Breadcrumb = {
  label: string;
  url: string;
  customLabel?: string;
  showCustomLabel?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  private _breadcrumbs = signal<Breadcrumb[]>([]);

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this._breadcrumbs.set(this.createBreadcrumbs(this.activatedRoute.root));
  
    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe(() => {
        this._breadcrumbs.set(this.createBreadcrumbs(this.activatedRoute.root));
      });
  }

  get breadcrumbs() {
    return this._breadcrumbs.asReadonly();
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: Breadcrumb[] = [],
  ): Breadcrumb[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }

      const label = child.snapshot.data['breadcrumb'];
      const customLabel = child.snapshot.data['dynamicBreadcrumb'];
      const showCustomLabel = child.snapshot.data['breadcrumbShowCustomLabel'];

      if (!breadcrumbs.find((x) => x.url === url)) {
        breadcrumbs.push({ label, url, customLabel, showCustomLabel });
      }
      return this.createBreadcrumbs(child, url, breadcrumbs);
    }

    return breadcrumbs;
  }
}
