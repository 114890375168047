<app-table [columns]="prizeColumns" [values]="prizes"></app-table>

<app-popup
  [isOpen]="descriptionPopup.isOpen"
  (isOpenChange)="descriptionPopup.isOpen = false"
>
  <div class="description-content">
    <h1 class="title">{{ "MANAGEMENT.PRIZE.DESCRIPTION" | translate }}</h1>
    <app-input
      class="prize-description-container"
      type="text-area"
      [control]="descriptionPopup.text"
      [readonly]="true"
      [textAreaHeight]="140"
    ></app-input>
  </div>
</app-popup>
