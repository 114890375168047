import { Component, Input } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { DateRange } from '../../models/date-range';
import { ONLY_DATE } from '../../constants/date-formats';

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrl: './input.component.scss'
})
export class InputComponent {
  @Input() control?: FormControl;
  @Input() label?: string;
  @Input() placeholder: string = "";
  @Input() type: "text" | "text-area" | "password" | "date" | "date-range" = "text";
  @Input() readonly?: boolean;
  @Input() testId: string = '';
  @Input() showRequiredStar = true;
  @Input() textAreaHeight = 140;

  rangeValue: DateRange = {};
  ONLY_DATE = ONLY_DATE;

  get controlIsRequired(): boolean {
    return !!this.control?.hasValidator(Validators.required);
  }

  setStartRange(date: Date) {
    this.rangeValue.start = date;
    this.setRange();
  }

  setEndRange(date: Date) {
    this.rangeValue.end = date;
    this.setRange();
  }

  setRange() {
    this.control?.patchValue(this.rangeValue);
  }
}
