import { Component, Input, ViewEncapsulation } from '@angular/core';

import { Prize } from '../../../../api/models/prize';
import { TableColumn } from '../../../models/table-column';
import { Promotion } from '../../../../api/models/promotion';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-prizes-table',
  templateUrl: './prizes-table.component.html',
  styleUrl: './prizes-table.component.scss',
  encapsulation: ViewEncapsulation.None,
})
export class PrizesTableComponent {
  @Input() prizes: Prize[] = [];
  @Input() promotion: Promotion | undefined;

  descriptionPopup = {
    isOpen: false,
    text: new FormControl<string>(''),
  };

  prizeColumns: TableColumn[] = [
    {
      type: 'text',
      label: 'MANAGEMENT.PRIZE.TIER',
      key: 'tier',
    },
    {
      type: 'text',
      label: 'MANAGEMENT.PRIZE.ID',
      key: 'prizeId',
    },
    {
      type: 'link',
      label: 'MANAGEMENT.PRIZE.DESCRIPTION',
      linkAction: this.openPrizeDescription.bind(this),
      linkText: 'MANAGEMENT.PRIZE.VIEW_MORE_LINK',
    },
    {
      type: 'radio-group',
      label: 'MANAGEMENT.PRIZE.IS-AUDITABLE',
      headerAlign: 'left',
      options: [
        { value: true, label: '' },
        { value: false, label: '' },
      ],
      initialOptionKey: 'auditable',
      testId: 'is-auditable-prize',
      onOptionChanged: this.updateAuditablePrize.bind(this),
      disabled: this.hasPromoStarted.bind(this),
      show: true,
    },
  ];

  updateAuditablePrize(prize: unknown, auditable: unknown) {
    const prizeParsed: Prize = prize as Prize;
    const auditableParsed: boolean = auditable as boolean;

    const foundPrize = this.prizes.find(
      (p) => p.prizeId === prizeParsed.prizeId
    );

    if (foundPrize) {
      foundPrize.auditable = auditableParsed;
    }
  }

  hasPromoStarted(): boolean {
    if (!this.promotion || !this.promotion.startDate) {
      return false;
    }

    const today = new Date();
    const startDate = new Date(this.promotion.startDate);

    return startDate <= today;
  }

  openPrizeDescription(row: unknown) {
    this.descriptionPopup.isOpen = true;
    this.descriptionPopup.text.patchValue((row as Prize).description);
  }
}
